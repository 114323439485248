import { useEffect, useState } from 'react';
import { useScreenWidth } from '@hooks/useScreenWidth';
import { Drawer } from 'antd';
import { CloseIcon } from "@/utils/icons";
import s from './index.module.css';
import './style.css';
import { notificationApi } from '@/api';
import { useAddAlert } from '@/hooks/useAddAlert';
import websocket from '@/utils/websocket';

const Notification = () => {
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  const isMobile = useScreenWidth(992);
  const width = isMobile ? '100vw' : '440px';
  const addAlert = useAddAlert();

  const onClose = () => {
    setOpen(false);

    setNotifications(prev =>
      prev.map(notification => ({
        ...notification,
        is_read: true,
      }))
    );
  };

  const showDrawer = () => {
    setOpen(true);
    if (unreadCount > 0) {
      markNotificationsAsRead();
    }
  };

  const markNotificationsAsRead = () => {
    notificationApi.markAsRead().then(() => {
      setUnreadCount(0);
    });
  };

  const fetchNotifications = () => {
    notificationApi.getNotifications().then((response) => {
      const newNotifications = response.data;
      setNotifications(newNotifications);
      setUnreadCount(newNotifications.filter(item => !item.is_read).length);
    });
  };

  useEffect(() => {
    websocket.connect();

    websocket.subscribe('notification', (newNotification) => {
      const notification = newNotification.notification;

      setNotifications(prev => [notification, ...prev]);
      setUnreadCount(prev => prev + 1);
      showNewNotificationAlerts(notification);
    });

    fetchNotifications();

    return () => {
      websocket.disconnect();
    };
  }, []);

  const showNewNotificationAlerts = (newNotification) => {
    addAlert({
      title: newNotification.title,
      text: newNotification.message,
      sound: true,
    });
  };

  return (
    <>
      <button onClick={showDrawer} className={s.button} title='Уведомления'>
        <div className={`${s.numberOfNotifications} ${unreadCount > 0 ? s.pulse : ''}`}>
          {unreadCount}
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M14.857 17.082C16.7202 16.8614 18.5509 16.4217 20.311 15.772C18.8204 14.1208 17.9967 11.9745 18 9.75V9C18 7.4087 17.3679 5.88258 16.2427 4.75736C15.1174 3.63214 13.5913 3 12 3C10.4087 3 8.8826 3.63214 7.75738 4.75736C6.63216 5.88258 6.00002 7.4087 6.00002 9V9.75C6.00304 11.9746 5.17901 14.121 3.68802 15.772C5.42102 16.412 7.24802 16.857 9.14302 17.082M14.857 17.082C12.959 17.3071 11.041 17.3071 9.14302 17.082M14.857 17.082C15.0011 17.5319 15.037 18.0094 14.9616 18.4757C14.8863 18.942 14.7019 19.384 14.4234 19.7656C14.145 20.1472 13.7803 20.4576 13.3592 20.6716C12.9381 20.8856 12.4724 20.9972 12 20.9972C11.5276 20.9972 11.0619 20.8856 10.6408 20.6716C10.2197 20.4576 9.85509 20.1472 9.57664 19.7656C9.29819 19.384 9.11379 18.942 9.03844 18.4757C8.96309 18.0094 8.99892 17.5319 9.14302 17.082M3.12402 7.5C3.40599 5.82497 4.15782 4.26444 5.29202 3M18.708 3C19.8422 4.26444 20.5941 5.82497 20.876 7.5" stroke="#2B2D3E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </button>

      <Drawer
        placement='right'
        closable={false}
        open={open}
        className='antDrawerStyle2002'
        width={width}
      >
        <div className={s.title}>Уведомления</div>
        {notifications.length ? (
          <ul className={s.notificationsList}>
            {notifications.map((notification) => (
              <li
                key={notification.id}
                className={`${s.notificationsListItem} ${!notification.is_read ? s.unread : ''}`}
              >
                {!notification.is_read && <div className={s.unreadDot}></div>}
                <div className={s.notificationsListItemTitle}>{notification.title}</div>
                <div
                  className={s.notificationsListItemMessage}
                  dangerouslySetInnerHTML={{
                    __html: notification.message.replace(/\n/g, '<br>'),
                  }}
                ></div>
                <div className={s.notificationsListItemDate}>
                  {new Date(`${notification.created_at}Z`).toLocaleString('ru-Ru', {
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  })}
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className={s.noNotifications}>Нет новых уведомлений.</div>
        )}
        <div className={s.close} onClick={onClose}>
          <CloseIcon />
        </div>
      </Drawer>
    </>
  );
};

export default Notification;
